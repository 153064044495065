import { setStorageValue, getStorageValue } from './storage';

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
    AUTO = 'auto',
}

const THEME_STORAGE_KEY = 'theme';

export function getTheme() {
    const allowedThemes = Object.values(Theme);
    const currentThemeValue = getStorageValue(THEME_STORAGE_KEY) as Theme;

    return allowedThemes.includes(currentThemeValue) ? currentThemeValue : Theme.AUTO;
}

export function setTheme(theme: Theme) {
    setStorageValue(THEME_STORAGE_KEY, theme);
}
